.gap-text-container{
    padding:3rem 0;
    margin:0 auto;
    max-width:60%;
}
/*.gap-text-container p{
    
}*/
.gap-text-title{
    font-size:1.75rem;
    margin-bottom:0.5rem;
}
.gap-text, .gap-text-quote{
    font-size:1.25rem;
    margin:0;
}
.gap-text{
    text-align:justify;
    /* text-align-last:center; */
}
.gap-text-quote{
    margin-top:0.5rem;
    text-align:right;
}
.gap-img{
    width:90px;
    float:left;
    margin:0 1rem 0.5rem 0;
    margin:0 0.5rem 0 0;
}

@media only screen and (max-width:880.5px){
    .gap-text-container{
        max-width:85%;
    }
}
.back{
    width:275px;
    height:200px;
    max-width: 275px;
    margin:1rem;
    display:inline-block;
    perspective:1000px;
    background-color: orange;
    border-radius: 5%;
    box-shadow: 0 0.8rem 0.8rem rgba(0,0,0,0.15);
    word-break: break-all;
}
.in{
    width:275px;
    height:200px;
    margin-top:0.25rem;
    display:inline-block;
    perspective:1000px;
    background-color: rgb(255, 255, 255);
    border-radius: 5%;
}

.title{
    font-size:1.125rem;
    margin-bottom:0;
    margin-left: 1rem;
    font-weight:bold;
}
.text{
    font-size:1rem;
    margin-bottom:0;
    margin-left: 1rem;
    margin-right: 1rem;
}

.parag-container{
    width: auto;
    height: 400px;
}
.parag-container img{
    width: auto;
    height:100%;
}
.legisls{
    display:flex;
    flex-basis: 100%;
    align-items: center;
    justify-content: center;
    margin:0 auto;
}
.legisls img{
}
.legisl-container{
    display:inline-flex;
    flex-wrap: wrap;
    max-width:616px;
    max-width:550px;
    align-items: center;
    justify-content: center;
}
.legis-title{
    color:rgb(247, 156, 38);
}

.cim{
    margin-top: 3rem;
}

@media only screen and (max-width:880.5px){
    .parag-container{
        height:6rem;
        margin:1rem 0;
    }
    .legisls{
        flex-wrap: wrap;
    }
    .legisls > div{
        flex-basis: 90%;
        width:90%;
    }
}
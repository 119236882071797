.navbar{
    position:absolute !important;
    top:0;
    left:0;
    width:100%;
    height:6rem;
    padding:0;
    color:white;
    text-shadow:0 0.2rem 1rem rgba(0,0,0,0.5);
    /* background-color:rgba(0,0,0,0.125); */
    perspective:300px;
    z-index:1;
    transition:background-color 0.25s;
}
.navbar-title{
    font-size:2.25rem;
    font-weight:bold;
    /* user-select:none; */
    text-align:center;
    float:left;
}
.navbar-phone{
    font-size:1.25rem;
}
.navbar p{
    margin:0;
}
.navbar .navbar-content{
    width:100%;
}
.navbar .top-row{
    width:100%;
    margin:0;
    padding-left:3rem;
    padding-right:3rem;
    align-items:center;
    /* height:108px; */
    position:relative;
    z-index:0;
    transition:height 0.25s, opacity 0.25s, transform 0.25s;
}
.navbar-shrunk{
    background-color:rgba(0,0,0,0.5);
    height:124px;
}
.navbar-shrunk .top-row{
    height:0;
    opacity:0;
    /* transform:rotateX(90deg); */
    user-select:none;
}
.navbar-content .name-col{
    /* padding-left:5rem; */
    text-align:left;
}
.navbar-content .name-col, .navbar-content .phone-col{
    padding:0;
}
.navbar-content .phone-col{
    /* padding-right:5rem; */
    text-align:right;
}
.navbar-items, .navbar-items-rep{
    list-style-type:none;
    text-align:right;
    padding:0 4rem 0 0;
    font-size:1.5rem;
    height:100%;
}
.navbar-item{
    display:inline-block;
    vertical-align:middle;
}
.navbar-items li{
    margin-left:1.75rem;
    display:inline-block;
}
.navbar-items li.navbar-title{
    padding:0 1rem;
    float:left;
    opacity:0;
    text-align:center;
    transition:opacity 0.25s;
}
.navbar-shrunk .navbar-items li.navbar-title{
    opacity:1;
}
.navbar-items li:not(.navbar-title){
    padding:0.5rem 1rem;
    /* background-color:red; */
    /* border-bottom:2px solid white; */
    cursor:pointer;
    font-variant-caps:small-caps;
}
.navbar-items li:not(.navbar-title):after{
    content:"";
    display:block;
    width:0%;
    margin:0.25rem auto 0;
    border-bottom:solid;
    transition:width 0.25s;
}
.navbar-items li:hover:after{
    width:100%;
}
.navbar-items-rep{
    background-color:rgba(0,0,0,0.2);
    margin-top:0.5rem;
    padding:0.25rem 0;
    text-align:center !important;
    display:none;
    cursor:pointer;
    transition:opacity 0.4s;
    user-select:none;
}
.navbar-items-rep p{
    display:inline-block;
    margin:0;
}
.navbar-fullscreen{
    width:100%;
    height:100%;
    position:fixed;
    display:none;
    align-items:center;
    justify-content:center;
    top:0;
    left:0;
    background-color:rgba(0,0,0,0.85);
    opacity:0;
    z-index:-1;
    color:white;
    text-shadow:0 0.2rem 1rem rgba(0,0,0,1);
    transition:opacity 0.4s, z-index 0.4s;
    /* backdrop-filter: blur(3px); */
}
body.menu-open .navbar-fullscreen{
    opacity:1;
    z-index:11;
}
.navbar-fullscreen .navbar-items{
    transition: transform 0.4s cubic-bezier(0.265, 0.005, 0.250, 1.000);
    transform:translateY(-100vh);
}
body.menu-open .navbar-items{
    transform:translateY(0);
}
body.menu-open .navbar-items-rep{
    opacity:0;
}
.down-arrow{
    font-size:85%;
    transform:translateY(-3px);
    /* animation:float infinite 2s ease-in-out; */
}
@keyframes float {
    0%{
        transform:translateY(-3px);
    }
    50%{
        transform:translateY(3px);
    }
    100%{
        transform:translateY(-3px);
    }
}
.menubtn{
    box-sizing: border-box;
    display: none;
    visibility: hidden;
    width: 100%;
    margin-top: 0.4rem;
    padding:0.25rem 0.75rem;
    color:white;
    font-size:1.25rem;
    border:0;
    background-color:rgba(144,144,144,0.75);
    border:2px solid rgb(192,192,192);
    transition:0.15s background-color;
}

.menubtn:hover{
    background-color:rgba(128,128,128,0.5);
}

/* @media screen and (max-width:872.5px){
    .name-col, .phone-col{
        width:100% !important;
        text-align:center !important;
    }
    .navbar-title{
        width:100%; 
    }
    .navbar-items{
        display: none;
        padding:0;
        text-align:center !important;

        position: relative;
        background-color: rgb(141,141,141);
        width: 100%;
        height: 100%;

    }
    .navbar-items li{
        margin:0 0.875rem;
    }
    .menubtn{
        visibility: visible;
        display: inline-block;
    }
}
@media screen and (min-width:872.5px) {
    .navbar-items{
        display: inline-block !important;
    }
} */

@media only screen and (max-width:880.5px){
    .name-col, .phone-col{
        width:100% !important;
        text-align:center !important;
    }
    .navbar-title{
        display:flex;
        justify-content:center;
        width:100%;
        margin-bottom:0.5rem !important;
        line-height:1.125;
    }
    .navbar-items{
        padding:0;
        text-align:center !important;
    }
    .navbar-items li{
        padding:0.4rem 0.75rem !important;
        margin:0 0.625rem;
    }
    .navbar-phone{
        font-size:1.075rem;
        line-height:1.25;
    }
}
@media only screen and (max-width:607.5px){
    .navbar-items:not(.navbar-items-mobile){
        display:none;
    }
    .navbar-items-rep{
        display:block;
    }
    .navbar-items-mobile{
        padding:2rem;
        height:auto;
        font-size:1.75rem;
    }
    .navbar-items-mobile .navbar-item{
        display:block;
    }
    .navbar-fullscreen{
        display:flex;
    }
    body.menu-open{
        overflow-y:hidden;
    }
}
.test{
    color:white;
    text-shadow:0 0.2rem 1rem rgba(0,0,0,0.5);
    width:100%;
    min-height:50vh;
    background-size:cover;
    background-repeat:no-repeat;
    background-position:50%;
    display:flex;
    justify-content:center;
    align-items: center;
}
.test-welcome{
    margin:2rem 0;
    max-width:70%;
    width:640px;
}
.test-welcome p:first-child{
    margin:0;
    font-size:3rem;
    font-weight:bold;
}
.test-welcome p:nth-child(2){
    margin:0;
    font-size:1.25rem;
    text-align:justify;
    text-align-last:left;
}

@media only screen and (max-width:880.5px){
    .test-welcome{
        max-width:85%;
    }
}
.footer{
    
    /* background-attachment:fixed; */
    height:28rem;
    color:white;
    font-weight:bold;
    /* font-variant-caps:small-caps; */
    /* background-color:rgb(32,32,32); */
    /* border-top:0.5rem solid rgba(0,0,0,0.125); */
}
.footer .adat{
    margin:0;
    display:flex;
    flex-wrap: wrap;
    flex-basis:100%;
    width:100%;
    background-color:rgb(77,77,77,1);
    height:100%;
    align-items: center;
    align-content: center;
}
.footer .adat > div{
    margin:1.5rem 0;
    width:100%;
    display:flex;
    flex-wrap: wrap;
    flex-basis:100%;
}
.footer a, .footer a:hover{
    color:white;
}
.footer .footerpic{
    margin:0 auto;
    width:100%;
    max-width:991px;
    max-width:800px;
}
.footer .footerpic-container{
    /* background-image:url("../images/line.png"); */
    background-size:100% 100%;
}
.latis-logo{
    width:300px;
    max-width:70%;
    text-align:center;
}
@media only screen and (max-width:607.5px){
    .footer{
        height:30rem;
    }
}
body {
  margin: 0;
  overflow-x:hidden;
  scroll-behavior: smooth;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h2{
  font-weight:bold !important;
  font-variant-caps:small-caps !important;
  font-size:2.25rem !important;
}

::-webkit-scrollbar {
  width: 8px;
  height: 12px;
}
::-webkit-scrollbar-track {
  background: #333;
}
::-webkit-scrollbar-thumb {
  background: rgba(0,0,0,0.25);
}
::-webkit-scrollbar-thumb:hover {
  background: rgba(0,0,0,0.40);
}
::-webkit-scrollbar-thumb:active {
  background: rgba(0,0,0,0.45);
}

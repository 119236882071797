.info-card-container, .serv-card-container, .legis-card-container{
    margin:1rem;
    display:inline-block;
    perspective:1000px;
}
.legis-card-container{
    margin:0.5rem 0.25rem;
}
.info-card-container{
    width:275px;
    height:200px;
}
.serv-card-container, .legis-card-container{
    width:300px;
    height:200px;
}
.legis-card-container{
    width:250px;
    height:170px;
}
.info-card, .serv-card, .legis-card{
    width:100%;
    height:100%;
    max-width:95%;
    background-color:white;
    border-radius:1rem;
    position:relative;
    display:inline-block;
    overflow:hidden;
    z-index:1;
    transition:box-shadow 0.15s, transform 0.25s, z-index 0.25s;
}
.serv-card{
    box-shadow:0 1rem 2rem rgba(0,0,0,0.2);
}
.legis-card{
    padding:1.5rem;
    border-top:0.325rem solid rgb(247, 156, 38);
    cursor:pointer;
    line-height:1.175;
}
.serv-card, .legis-card{
    display:flex;
    margin:0 auto;
    align-items:center;
    justify-content:center;
}
.info-card, .legis-card{
    box-shadow:0 0.25rem 0.5rem rgba(0,0,0,0.15);
}
.info-card:hover, .legis-card:hover{
    box-shadow:0 1rem 2rem rgba(0,0,0,0.25);
    z-index:10;
}
.info-card-icon, .serv-card-icon{
    width:5rem;
    height:5rem;
    margin:0 auto 0 auto;
    background-color:white;
    border-radius:50%;
    display:flex;
    align-items:center;
    justify-content:center;
    transform:translateY(calc(50% - 1rem));
}
.info-card-icon{
    transform:translateY(calc(50% - 1rem));
}
.serv-card-icon{
    transform:translateY(0);
}
.info-card-icon *, .serv-card-icon *{
    width:3.25rem;
    height:3.25rem;
}
.info-card-bottom{
    margin-top:-1rem;
    padding-top:2.5rem;
    border-radius:1rem 1rem 0 0;
    background-color:white;
}
.serv-card-full, .legis-card-full{
    margin-left:0;
    margin-right:0;
    border-radius:1rem 1rem 0 0;
    background-color:white;
}
.info-card-title, .serv-card-title, .legis-card-title{
    color:black;
    font-size:1.125rem;
    margin-bottom:0;
    font-weight:bold;
}
.legis-card-text{
    color:black;
    margin-bottom:0;
}

@media only screen and (max-width:607.5px){
    .serv-card-container{
        margin:1rem 0;
    }
}

/* VARIANTS */
/* .card-default .info-card-top{ */
.card-default .info-card-top{
    background-color:rgb(91, 142, 209);
}
.card-default > div:first-child{
    color:rgb(91, 142, 209);
}
.card-orange .info-card-top{
    background-color:rgb(247, 156, 38);
}
.card-orange > div:first-child{
    color:rgb(247, 156, 38);
}
.card-green .info-card-top{
    background-color:rgb(106, 221, 39);
}
.card-green > div:first-child{
    color:rgb(106, 221, 39);
}

.header{
    color:white;
    text-shadow:0 0.2rem 1rem rgba(0,0,0,0.5);
    width:100%;
    height:100vh;
    background-size:cover;
    background-repeat:no-repeat;
    background-position:50%;
    display:flex;
    justify-content:center;
    align-items: center;
}
.header-item{
    width:100vw;
    height:100vh;
    object-fit:cover;
}
.header-welcome{
    max-width:70%;
    width:640px;
}
.header-welcome p:first-child{
    margin:0;
    margin-bottom:0.25rem;
    font-size:3rem;
    font-weight:bold;
    line-height:1;
}
.header-welcome p:nth-child(2){
    margin:0;
    margin-bottom:0.25rem;
    font-size:1.5rem;
    /* text-align:justify;
    text-align-last:center; */
    text-align:center;
    line-height:1;
}
.header-welcome p:nth-child(3){
    font-style:italic;
}


.header{
    width:100%;
    height:100vh;
    overflow:hidden;
    border-radius:0 !important;
    position:relative;
    z-index:0;
}
.header .carousel-inner{
    background-color:black;
    height:100%;
}
/* .header .carousel-item{
    backface-visibility: visible;
    height:100%;
} */
/* .header .carousel-item:after{
    content:'';
    position:absolute;
    top:0;
    right:0;
    bottom:0;
    left:0;
    z-index:1;
} */
.header .carousel-item img{
    width:100%;
    height:100vh;
    object-fit:cover;
    user-select:none;
}
.header .carousel-caption{
    left:50%;
    right:auto;
    top:50%;
    bottom:auto;
    transform:translate(-50%,-50%);
    /* user-select:none; */
}

@media only screen and (max-width:880.5px){
    .header-welcome p:first-child{
        font-size:2.5rem;
    }
    .header-welcome p:nth-child(2){
        font-size:1.25rem;
    }
    .header-welcome p:first-child{
        line-height:1;
    }
    .header-welcome p:nth-child(2){
        line-height:1;
    }
}
@media only screen and (max-width:800.5px) and (orientation:landscape){
    .header{
        height:165vh;
    }
    .header-item{
        height:165vh;
    }
    .header .carousel-item img{
        height:165vh;
    }
}
.cbutton{
    margin:0.5rem;
    padding:0.25rem 0.75rem;
    color:white;
    font-size:1.25rem;
    border:0;
    border-radius:0.25rem;
}
.cbutton-default{
    background-color:rgba(144,144,144,0.75);
    border:2px solid rgb(192,192,192);
    transition:0.15s background-color;
}
.cbutton-default:hover{
    background-color:rgba(128,128,128,0.5);
}
.cbutton-danger{
    background-color:red;
}
